@import "./icons.scss";

@import "./bootstrap.scss";
@import "./app.scss";

// RTL
// @import "./rtl/general-rtl";
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/text-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/pages-rtl";

@import "./custom.scss";
